<template>
  <div class="w_message">
    <div class="w_message_box">
      <div class="title">账号信息</div>
      <div class="list">
        <div class="item">
          <div class="l_key">账号: </div>
          <div class="r_value">{{ data.user_info.tel }}</div>
        </div>
        <div class="item">
          <div class="l_key">称呼: </div>
          <div class="r_value">{{ data.user_info.real_name ? data.user_info.real_name : '--' }}</div>
        </div>
        <div class="item">
          <div class="l_key">部门: </div>
          <div class="r_value">{{ data.user_info.department ? data.user_info.department : '--' }}</div>
        </div>
        <div class="item">
          <div class="l_key">职位: </div>
          <div class="r_value">{{ data.user_info.zhiwei ? data.user_info.zhiwei : '--' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { showToast } from 'vant';
import 'vant/es/toast/style';
import { reactive, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { localStorage } from '../../utils/storage';

const _this = getCurrentInstance().appContext.config.globalProperties;
const $router = useRouter();

const data = reactive({
  user_info: ''
});

function GetUserInfo() {
  var _url = "/tools/submit_ajax.ashx?action=get_user_info";
  _this.$http.post(_url, {}).then(function (res) {
    if (res.data.status == 1) {
      data.user_info = res.data.data.user_info;
    } else if (res.data.status == 3) {
      localStorage.remove('user_info');
      $router.push({ name: 'login' });
    }
    else {
      showToast(res.data.msg);
    }
  }.bind(this)).catch(function () {
  });
}

GetUserInfo();


</script>